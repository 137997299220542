import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { KeyboardArrowRight } from "styled-icons/material/KeyboardArrowRight"
import { Linkedin } from "styled-icons/fa-brands/Linkedin"
import { useIntl } from "gatsby-plugin-intl"

const IndexPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title="Home" />
        <h1>Youri Bondu 
          <span className="subtitle">
            {intl.formatMessage({ id: "INDEX.SUBTITLE" })}
          </span>
        </h1>
        <p>{intl.formatMessage({ id: "INDEX.DESCRIPTION" })}</p>
        <ul className="list-skills">
          <li><KeyboardArrowRight size={24} style={{ color: "#348AFF" }}/> <strong>{intl.formatMessage({ id: "INDEX.MOBILE_APP" })}</strong></li>
          <li><KeyboardArrowRight size={24} style={{ color: "#348AFF" }}/> <strong>{intl.formatMessage({ id: "INDEX.WEB_PLATFORM" })}</strong></li>
          <li><KeyboardArrowRight size={24} style={{ color: "#348AFF" }}/> <strong>{intl.formatMessage({ id: "INDEX.SCRAPING" })}</strong></li>
        </ul>
        <div className="social-network">
          <a href="https://www.linkedin.com/in/youri-bondu-88109691/" target="_blank" rel="noopener noreferrer">
            <Linkedin style={{ color: "#348AFF" }} size="32" />
          </a>
        </div>
    </Layout>
  )
}

export default IndexPage
